div.photo-item
{
	position: relative;
	display: block;

	@extend .p-1;

	a.photo-link
	{
		position: relative;
		display: block;
		padding-top: 100%;
		border-radius: var(--radius);
		overflow: hidden;

		img
		{
			position: absolute;
			display: block;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			object-fit: cover;
			object-position: center;
			transition: transform 1s var(--easeDecelerationCurve);
		}

		&:hover img
		{
			transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
		}
	}
}
