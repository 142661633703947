h1
{
	color: rgba(var(--colorPrimaryLight), 1);
	font-weight: 600;
}

h2
{
	color: rgba(var(--colorPrimary), 1);
	font-size: 2rem;
	font-weight: 700;
}

h3
{
	color: rgba(var(--colorPrimaryLight), 1);
	font-size: 1.4rem;
	font-weight: 500;

	@at-root p + &
	{
		margin-top: 2rem;
	}
}

p
{
	line-height: 2em;
}
