.possibility
{
    align-items: center;
    justify-content: center;

    &-photos
    {
        position: relative;
        display: grid;
        grid-gap: 12px;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);

        &-photo
        {
            position: relative;
            padding-bottom: 100%;
        }

        img
        {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            height: 100%;
            width: 100%;
            border-radius: var(--radius);
            object-fit: cover;
            object-position: center;
        }
    }
}
