.album
{
	position: relative;
	display: block;
	padding-top: 100%;
	border-radius: var(--radius);
	opacity: 0;
	overflow: hidden;
	transition: opacity 540ms var(--easeSwiftOut);
	z-index: 0;

	&.is-ready
	{
		opacity: 1;
	}

	div.album-caption
	{
		position: absolute;
		display: flex;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 18px 30px;
		align-items: flex-start;
		flex-flow: column;
		justify-content: flex-start;
		border-radius: inherit;
		color: white;
		text-shadow: 0 1px 2px rgba(0, 0, 0, .5);
		transform: translate3d(0, calc(100% - 66px), 0);
		transition: transform 540ms var(--easeSwiftOut), background 420ms var(--easeSwiftOut) 120ms;
		z-index: 1;

		strong, span
		{
			transition: all 540ms var(--easeSwiftOut);
		}

		span
		{
			opacity: 0;
			transform: translate3d(0, 48px, 0);
			transition-delay: 100ms;
		}
	}

	div.album-preview
	{
		position: absolute;
		display: block;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 0;

		img
		{
			position: absolute;
			display: block;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			object-fit: cover;
			object-position: center;
			opacity: 0;
			transition: opacity 660ms var(--easeSwiftOut), transform 1s var(--easeDecelerationCurve);

			&.is-current
			{
				opacity: 1;
				z-index: 1;
			}
		}
	}

	&:hover
	{
		div.album-caption
		{
			background: rgba(var(--colorPrimaryLight), .9);
			text-shadow: none;
			transform: none;

			@at-root .col-lg-6 > &
			{
				transform: translate3d(0, 65%, 0);
			}

			span
			{
				margin-top: 12px;
				opacity: 1;
				transform: none;
			}
		}

		div.album-preview img
		{
			transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
		}
	}
}

section.album-text-block
{
	position: relative;
	display: flex;
	padding: 60px;
	align-items: center;
	flex-flow: column;
	justify-content: center;
	background: rgba(var(--colorPrimary), 1);
	border-radius: var(--radius);
	color: rgba(var(--colorPrimaryForeground), 1);

	@include media-breakpoint-down(md)
	{
		padding: 60px 30px;
	}
}

section.album-page
{
	position: relative;
	margin: 2rem 0;

	&.album-page-content
	{
		display: block;
	}

	&.album-page-photos
	{
		display: flex;
		margin: 0 -.25rem;
		align-items: stretch;
		flex-flow: row wrap;
		justify-content: flex-start;

		@include media-breakpoint-up(xl)
		{
			@media (min-width: 1600px)
			{
				margin: 0 -150px;
			}
		}

		.photo-item
		{
			width: 20%;

			@include media-breakpoint-down(md)
			{
				width: 33.3333%;
			}

			@include media-breakpoint-down(xs)
			{
				width: 50%;
			}
		}
	}
}
