.app-bar.app-bar-main
{
	--tabsAccent: var(--colorPrimaryLight);
	--tabsHeight: var(--appBarHeight);

	position: sticky;
	top: -121px;

	.nav-link
	{
		font-weight: 600;
	}
}
